<iframe width="100%" 
        height="315" 
        src=""
        title="Video"
        id="youtube-iframe-{index}" 
        frameborder="0"
        allowfullscreen>
</iframe>

<script>
	import { onMount, afterUpdate } from 'svelte';
    import { currentMarkerIndex } from '../stores.js';

	export let url;
	export let index;
    export let youTubeIframeAPIReady;

	let resized = false;
	let loaded = false;
    let iframeId = 'youtube-iframe-' + index
	let iframe;
    let player;

    onMount(() => {
        iframe = document.getElementById(iframeId);
    })

    afterUpdate(() => {
    	if($currentMarkerIndex === index && !loaded) {
            iframe.src = "https://www.youtube.com/embed/" + url + '?enablejsapi=1';
            loaded = true;
        }
        if($currentMarkerIndex !== null && !resized) {
            iframe.height = iframe.parentNode.clientWidth / 1.777;
            resized = true;
        }
        if($currentMarkerIndex === index && youTubeIframeAPIReady && !player) {
            player = new YT.Player(iframeId, { 
                playerVars: { 
                    cc_load_policy: 1, 
                    cc_lang_pref: 'en' 
                } 
            });
        }
        if($currentMarkerIndex != index && player && player.getPlayerState && player.getPlayerState() == YT.PlayerState.PLAYING) {
            player.pauseVideo()
        }
    });
</script>

