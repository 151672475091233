<div>
    <a href="http://www.surfrider.eu">
        <img class="sidebar_logo" src="/media-carte-interactive/img/logo-srfe-2020.png" alt="Logo Surfrider Foundation Europe" />
    </a>
    <LangMainMenu />
    <div class="sidebar_intro">
        <h1 class="sidebar_intro_title">{$_('page.home.intro_title')}</h1>
        {@html $_('page.home.intro_text') }
        {#if showMoreText }
        {@html $_('page.home.intro_text_more') }
        {/if}
        <p class="sidebar_intro_cta">
            <a on:click="{ () => showMoreText = !showMoreText }" href="#">{ toggleTextWording }</a>
        </p>
        <h2 class="sidebar_intro_ctas_title">{ $_('page.home.ressource_title') }</h2> 
        <div class="sidebar_intro_ctas">
            {#each links as { label, url }}
                <Link {url}>{ label }</Link>
            {/each}
        </div>
    </div>
</div>

<script>
    import Link from './Link.html';
    import LangMainMenu from './LangMainMenu.svelte';
    import { _ } from 'svelte-i18n';

	let showMoreText = false;
    $: links = [
        {
            label: $_('page.home.ressource_link_1'),
            url: "https://fr.oceancampus.eu/cours/u2y/les-risques-derosion-et-de-submersion"
        },
        {
            label: $_('page.home.ressource_link_2'),
            url: "https://fr.oceancampus.eu/cours/xUz/les-strategies-de-gestion-du-trait-de-cote"
        }
    ]
	$: toggleTextWording = showMoreText ? $_('page.home.show_less') : $_('page.home.show_more')
</script>