<svelte:window bind:innerWidth={windowWidth} />

<div class="map-wrapper" style="width: {$width}%;">
    <img class="logo-eu" width="66" height="auto" src="/media-carte-interactive/img/logo-eu-carte.png" alt="Logo Union Europeenne">
    <MarkerKeys { types } />
    <span class="open-info" on:click="{ () => dispatch('toggleModal') }">
        <svg><use xlink:href="#icon-info"></use></svg>
    </span>
    <ShareOptions />
    <GoogleMap { markers } />
</div>

<script>
    import MarkerKeys from './MarkerKeys.html';
    import ShareOptions from './ShareOptions.html';
    import GoogleMap from './GoogleMap.html';
    import { beforeUpdate, createEventDispatcher } from 'svelte';
    import { currentMarkerIndex } from '../stores.js';
    import { tweened } from 'svelte/motion';

    export let types;
    export let markers;

    let windowWidth;

    const width = tweened(60, {
        duration: 200,
        delay: 100,
    });

    const dispatch = createEventDispatcher();

    beforeUpdate(() => {
        if($currentMarkerIndex != null && windowWidth >= 650) {
            width.set(50);
        } else if(windowWidth >= 650){
            width.set(60, { duration: 0 });
        }
    })

</script>
