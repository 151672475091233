<script>
    import { slide } from 'svelte/transition';
    import { cubicInOut } from 'svelte/easing';
    import { markerLang } from '../stores';
    import { currentMarkerIndex } from '../stores.js';

    let isOpen = false;

    $: currentLang = $currentMarkerIndex ? $markerLang[$currentMarkerIndex].markerISOlang : '';
    $: langs = $currentMarkerIndex ? $markerLang[$currentMarkerIndex].langues : [];
    $: deg = (isOpen ? "-90" : "90") + "deg";

    function handleLangChange(lang) {
        markerLang.update(state => {
            state[$currentMarkerIndex].markerISOlang = lang;
            return state;
        })
        isOpen = false;
    }


</script>

<div class="menu">
    <div class="trigger" on:click={ () => isOpen = !isOpen }>
        <strong>{currentLang}</strong>
        <span class="arrow" style="transform: rotate({deg})"></span>
    </div>
    {#if isOpen}
    <ul transition:slide={{ duration: 300, easing: cubicInOut }}>
    {#each langs as lang}
        {#if lang != currentLang}
            <li on:click={ () => handleLangChange(lang)}>{lang}</li>
        {/if}
    {/each}
    </ul>
    {/if}
</div>

<style>
    .menu {
        font-size: 0.85em;
        position: relative;
        width: 50px;
    }
    .trigger {
        cursor: pointer;
        padding-left: 3px;
        color:rgb(255, 255, 255)
    }
    .arrow {
        display: block;
        position: absolute;
        top: 4px;
        right: 0;
        height: 13px;
        width: 7px;
        line-height: 13px;
        font-size: 0.8em;
        font-family: swiper-icons;
        transform-origin: center center;
        transition: all 200ms;
        pointer-events: none;
        color: rgba(255, 255, 255, 0.5);
    }
    .arrow:after {
        content: "next";
        font-variant: initial;
        line-height: 1;
    }
    ul {
        list-style: none;
        padding: 2px 0;
        margin: 2px 0 0;
        box-shadow: 1px 1px 4px rgba(0,0,0,0.3);
        background-color: #fff;
        display: inline-block;
        overflow: hidden;
    }
    li, strong {
        text-transform: uppercase;
    }
    li {
        cursor: pointer;
        color: rgb(97, 97, 97);
        padding: 3px 4px;
        margin: 1px 0;
        line-height: 1;
        font-size: 0.95em;
    }
    li:hover {
        color:#fff;
        background-color: #2484C4;
    }
</style>

