import Main from './components/Main.html';

const app = new Main({
    target: document.querySelector('.app'),
    props: { 
        markers: Data.markers
    }
});

export default app;
