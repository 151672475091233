<img bind:this={thisImage}
	 class:loaded
	 style="display: { shouldDisplayPhoto ? 'block' : 'none' }"
     on:click="{ () => dispatch('beforeAfterClicked', index) }" />

<script>
    import { createEventDispatcher, onMount, afterUpdate } from 'svelte';
    import { currentMarkerIndex } from '../stores.js';
	
	export let src;
	export let index;
	export let isActiveMarker;
	export let shouldDisplayPhoto;
	export let preloadAfterImg;

	let loaded = false
	let thisImage;

	const dispatch = createEventDispatcher();

    onMount(() => {
    	thisImage.onload = () => {
      		loaded = true;
      		index == 0 && dispatch('beforeAfterImageLoaded', $currentMarkerIndex);
    	}
  	})

  	afterUpdate(() => {
  		if((!loaded && isActiveMarker && shouldDisplayPhoto) || (preloadAfterImg && index == 1 && !loaded)) {
  			thisImage.src = src
  		}
  	})

</script>

<style>
	img {
		opacity: 0;
		transition: opacity 300ms ease-in;
	}
	img.loaded {
		opacity: 1;
	}
</style>