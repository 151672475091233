<svelte:window bind:innerWidth={windowWidth} />

<div id="map"></div>

<script>
    import Consts from '../Consts.js';
    import { onMount, afterUpdate, beforeUpdate } from 'svelte';
    import { currentMarkerIndex } from '../stores.js';

    export let markers;

    let windowWidth;
    let map;
    let pins = [];
    let markerCluster;
    let animationDelay;

    afterUpdate(() => {
        if($currentMarkerIndex >= 0 && $currentMarkerIndex != null) {
            if(map.zoom != Consts.detailMapZoom) {
                map.setZoom(Consts.detailMapZoom);
            }
            map.panTo(pins[$currentMarkerIndex].getPosition());

            stopPinAnimations(pins);
            animationDelay = setTimeout(function() {
                pins[$currentMarkerIndex].setAnimation(google.maps.Animation.BOUNCE);
                clearTimeout(animationDelay);
            }, 500)
        } else if($currentMarkerIndex === null) {
            stopPinAnimations(pins);
            map.setZoom(Consts.initialMapZoom);
            map.setCenter(Consts.initialMapCenter);
        }
    })

    onMount(() => {        
        var initMap = function initMap() {
            map = new google.maps.Map(document.getElementById('map'), {
                center: Consts.initialMapCenter,
                zoom: Consts.initialMapZoom,
                styles: Consts.mapStyles,
                mapTypeControl: true,
                mapTypeControlOptions: {
                  style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  position: google.maps.ControlPosition.BOTTOM_LEFT,
                },
                fullscreenControl: false,
                streetViewControl: false,
                gestureHandling: 'greedy'
            });
            var infowindow = new google.maps.InfoWindow();
            for(var i = 0; i < markers.length; i++) {
                var marker = markers[i]
                var markerIcon = '/media-carte-interactive/img/marker-';
                markerIcon += (marker.type + 1) + '.png';
                var pinIcon = new google.maps.MarkerImage(
                    markerIcon,
                    null,
                    null,
                    null,
                    new google.maps.Size(24,36)
                );
                var pin = new google.maps.Marker({
                    map: map,
                    icon: pinIcon,
                    position: {lat: marker.lat, lng: marker.lng},
                    index: i,
                    title: marker.title
                });
                if(marker.nom_du_lieu) {
                    pin.nom_du_lieu = marker.nom_du_lieu
                }
                if(marker.nom_du_lieu) {
                    google.maps.event.addListener(pin, 'mouseover', function () {
                        infowindow.setContent('<strong>' + this.nom_du_lieu + '</strong>');
                        infowindow.open(map, this);
                    })
                    google.maps.event.addListener(pin, 'mouseout', function () {
                        infowindow.close();
                    })
                } 
                pins.push(pin);
                pin.addListener('click', function() {
                    if(this.index !== $currentMarkerIndex) {
                        currentMarkerIndex.set(this.index);
                    }
                });
            }
            var circle = 'data:image/svg+xml;base64,'+window.btoa('<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 32 32" width="30" ><circle cx="16" cy="16" r="15" fill="#2484C4" /></svg>');
            
            markerCluster = new MarkerClusterer(map, pins,
                { 
                    gridSize: 16, 
                    maxZoom: 10,
                    zoomOnClick: true,
                    averageCenter: true, 
                    styles:[
                        { textColor:'white', width:30, height:30, url: circle }
                    ]
                }
            );
        };
        
        if(google) {
            initMap()
        } else {
            this.mapInitInterval = setInterval(
                function () {
                    if(google) {
                       initMap()
                       clearInterval(this.mapInitInterval);
                    }
                }.bind(this), 300
            )
        }

    })

    function stopPinAnimations(pins) {
        pins.forEach(pin => {
            pin.animating && pin.setAnimation(null)
        })
    }

</script>
