<div class="sidebar_navbar" style="background-color: { headerColor }">
    <span class="sidebar_navbar_back" on:click="{handleNavBackClick}">
        <svg><use xlink:href="#icon-arrow-left"></use></svg>
    </span>
    <span class="sidebar_navbar_title">{ title }</span>
    <span class="lang-menu">
        <LangMenu />
    </span>
</div>

<script>
    import { currentMarkerIndex } from '../stores.js';
    import LangMenu from './LangMenu.svelte';
	
	export let title;
	export let headerColor;

	function handleNavBackClick() {
		currentMarkerIndex.set(null)
	}
</script>

<style>
    .lang-menu {
        position: absolute;
        z-index: 1;
        top: 18px;
        right: 18px;
    }
</style>