<svelte:window bind:innerWidth={windowWidth}/>

<div class="marker-info_before-after">
    <button class="marker-info_before-after_btn { addActiveClass('before', period) }" on:click="{() => togglePeriod('before')}">{ $_("page.marker.before_after.before") }</button><button class="marker-info_before-after_btn { addActiveClass('after', period) }" on:click="{() => togglePeriod('after') }" on:mouseenter={handleHoverAfterNavBtn}>{ $_("page.marker.before_after.after") }</button>
    <span class="marker-info_before-after_wrapper" style="min-height: 200px">
        {#if windowWidth > mobileLayoutMinWidth }
        <svg><use xlink:href="#icon-zoom-in"></use></svg>
        {/if}
        {#each photos as photo, i}
            <BeforeAfterImg 
                src={ photo }
                { preloadAfterImg }
                index={ i }
                shouldDisplayPhoto={ indexPhotoToDisplay == i }
                isActiveMarker={ $currentMarkerIndex == index } 
                on:beforeAfterClicked
                on:beforeAfterImageLoaded />
        {/each}
    </span>
</div>


<script>
    import BeforeAfterImg from './BeforeAfterImg.html';
    import Consts from '../Consts.js';
    import { currentMarkerIndex } from '../stores.js';
    import { _ } from 'svelte-i18n';

    export let index;
    export let photos;

    let afterNavButtonHovered = false;
    let windowWidth;
    let period = 'before';
    let mobileLayoutMinWidth = Consts.mobileLayoutMinWidth;

    function addActiveClass(btnPeriod, currentPeriod) {
        return btnPeriod === currentPeriod ? 'active' : '';
    }

    function togglePeriod(value) {
        if(period !== value) {
            period = value;
        }
    }

    function handleHoverAfterNavBtn() {
        afterNavButtonHovered = true;
    }

    $: indexPhotoToDisplay = period == 'before' ? 0 : 1;
    $: preloadAfterImg = afterNavButtonHovered;

</script>

