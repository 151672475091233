<svelte:head>
    <script type="text/javascript" charset="UTF-8" src="https://www.youtube.com/iframe_api"></script>
</svelte:head>

<div class="swiper-container swiper-markers-info">
    <div class="swiper-wrapper">
        {#each markers as marker, index}
        <div class="swiper-slide sidebar-markers_marker">
            <MarkerInfo { marker }  
                        { index }
                        { youTubeIframeAPIReady }
                        typeColor='{ types[marker.type].color }' 
                        on:beforeAfterClicked="{beforeAfterClicked}"
                        on:beforeAfterImageLoaded="{beforeAfterImageLoaded}" />
        </div>
        {/each}
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
</div>

{#if showImgModal }
<ImgModal photos={ photosForModal }
          startIndex={ showImgModalIndex }
          on:toggleModal={ toggleImgModal } />
{/if}

<script>

	import { onMount, afterUpdate } from 'svelte';
    import MarkerInfo from './MarkerInfo.html';
    import PerfectScrollbar from 'perfect-scrollbar';
    import { Swiper, Navigation, Keyboard } from 'swiper';
    import MarkerKey from './MarkerKey.html';
    import ImgModal from './ImgModal.html';
    import { currentMarkerIndex } from '../stores.js';

    Swiper.use([Navigation, Keyboard]);

    export let markers;
    export let types;

    let mySwiper;
    let youTubeIframeAPIReady = false;
    let showImgModal = false;
    let showImgModalIndex = 0;
    let scrollers = {}
    let slides;
    let windowHeight;

	window.onYouTubeIframeAPIReady = function() {
        youTubeIframeAPIReady = true
    }

    $: photosForModal = $currentMarkerIndex !== null && markers[$currentMarkerIndex].photos !== undefined ? markers[$currentMarkerIndex].photos : []

    onMount(() =>{
    	slides = document.querySelectorAll('.swiper-markers-info .swiper-slide');
    })

    afterUpdate(() => {
    	if(mySwiper) {
            if($currentMarkerIndex !== mySwiper.realIndex) {
                mySwiper.slideTo($currentMarkerIndex, 300);
            }
            showImgModal ? mySwiper.keyboard.disable() : mySwiper.keyboard.enable()
        } else if($currentMarkerIndex !== null) {
            let navBarHeight = document.querySelector('.sidebar_navbar').clientHeight;
            document.querySelector('.swiper-markers-info').style.height = window.innerHeight - navBarHeight + 'px';
            mySwiper = new Swiper ('.swiper-markers-info', {
                simulateTouch: false,
                on: {
                	slideChangeTransitionEnd: (swiper) => {
	                    if($currentMarkerIndex !== swiper.realIndex) {
	                        currentMarkerIndex.set(swiper.realIndex);
	                    }
	                    if($currentMarkerIndex >= 0 && !scrollers[$currentMarkerIndex]) {
	                    	scrollers[$currentMarkerIndex] = new PerfectScrollbar(slides[$currentMarkerIndex]);
	                    }
                	}
            	}, 
                initialSlide: $currentMarkerIndex,
                navigation: {
    				nextEl: '.swiper-button-next',
    				prevEl: '.swiper-button-prev',
  				},
                keyboard: true
            });
        }
    })

    function toggleImgModal() {
        showImgModal = !showImgModal
    }
    function beforeAfterClicked(event) {
        showImgModalIndex = event.detail;
        toggleImgModal();
    }
    function onYouTubeIframeAPIReady(event) {
    	youTubeIframeAPIReady = true;
    }
    function beforeAfterImageLoaded({ detail }) {
    	scrollers[detail].update();
    }
	
</script>
