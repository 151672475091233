<svelte:window on:keydown={keydown} bind:innerWidth={width} bind:innerHeight={height} />

<div class="modal-background" on:click="{ () => dispatch('toggleModal') }"></div>
<div class="modal modal-lightbox" style="height: { modalHeight }px; width: { modalWidth }px;">
    <div class="swiper-container swiper-modal-img" >
        <div class="swiper-wrapper">
            {#each photos as photo, index}
            <div class="swiper-slide" style="height: { modalHeight }px; width: { modalWidth }px;">
                <img src="{ photo }" style="max-height: { height - 100 }px" alt="">
            </div>
            {/each}
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
    </div>
     <span class="modal-lightbox_close" on:click="{ () => dispatch('toggleModal') }">
        <svg><use xlink:href="#icon-close"></use></svg>
    </span>
</div>

<script>
    import { Swiper, Navigation, Keyboard } from 'swiper';
    import { beforeUpdate, afterUpdate, createEventDispatcher, onMount } from 'svelte';

    Swiper.use([Navigation, Keyboard]);

    export let photos;
    export let startIndex;

    let width;
    let height;
    let mySwiperImg;
    let swiperEL;
    let modalMargin = 40

    const dispatch = createEventDispatcher();

    $: modalWidth = width - modalMargin;
    $: modalHeight = height - modalMargin;

    afterUpdate(() => {
    	if(photos.length && !mySwiperImg) {
            mySwiperImg = new Swiper('.swiper-modal-img', {
                simulateTouch: false,
                initialSlide: startIndex,
                preloadImages: true,
                updateOnImagesReady: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    beforeDestroy: (swiper) => {
                	   dispatch('toggleModal');
                    },
                    imagesReady: (swiper) => {
                        swiper.update(false);
                    }
                },
                keyboard: true
            });
        }
    })

    function keydown(event) {
        if(event.key === 'Escape') {
        	mySwiperImg.destroy()
        }
    }

</script>
