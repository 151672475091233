<div class="marker-key">
    <div class="marker-key_top">
    	<img class="marker-key_img" width="24" height="36" src="/media-carte-interactive/img/marker-{ index + 1 }.png" alt="marker">
    	<h2 class="marker-key_title">{ type.title }</h2>
    </div>
    <p class="marker-key_description">{ type.description }</p>
</div>

<script>
	export let index;
	export let type;
</script>

