<svelte:window bind:innerHeight={height} bind:innerWidth={width} />

<div class="app">
    {#if $currentMarkerIndex != null }
    <div transition:fly="{{ x: -sidebarMarkersWidth, opacity: 1, duration: 200 }}" class="sidebar-markers">
        <NavBar title='{ currentTypeTitle }'
                headerColor='{ currentTypeTitleColor }' />
        <MarkersInfo { markers } { types } />
    </div>
    {/if}
    <div class="sidebar { sidebarDrawClass }" style="height: { height }px;">
        <div class="sidebar_scroller" style="height: { height }px;">
            <div class="sidebar_inner">
                <Intro on:toggleModal="{toggleModal}" />
            </div>
            <div class="sidebar_draw-handle" on:click="{ () => showDraw = !showDraw }">
                {#if showDraw}
                <img src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E" alt="" width="15" height="24">
                {:else}
                <img src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E" alt="" width="15" height="24">
                {/if}
            </div>
        </div>
    </div>
    <Map { markers }
         { types }
         on:toggleModal="{toggleModal}" />
    {#if showModal }
    <Modal on:toggleModal="{toggleModal}" />
    {/if}
</div>

<script>
	import { onMount, afterUpdate } from 'svelte';
    import { fly } from 'svelte/transition';
    import MarkersInfo from './MarkersInfo.html';
    import Map from './Map.html';
    import Modal from './Modal.html';
    import Intro from './Intro.html';
    import NavBar from './NavBar.html';
    import PerfectScrollbar from 'perfect-scrollbar';
    import Consts from '../Consts.js';
    import { currentMarkerIndex, markerLang } from '../stores.js';
    import { _, addMessages, init, locale, getLocaleFromNavigator } from 'svelte-i18n';
    import fr from '../../locale/fr.json';
    import en from '../../locale/en.json';
    import es from '../../locale/es.json';
    import pt from '../../locale/pt.json';

    addMessages('fr', fr);
    addMessages('en', en);
    addMessages('es', es);
    addMessages('pt', pt);

    init({
        fallbackLocale: 'fr',
        initialLocale: getLocaleFromNavigator(),
    });

    $: if($locale) {
        document.title = $_("title");
    }

    let height;
    let width;
    let showModal = false;
    let showDraw = false;
    let sidebar;
    let ps;

    export let markers;

    $: globalISOlang = $locale.substr(0, 2);

    $: {
        for(let index in markers) {
            let marker = markers[index];
            let markerISOlang;
            if(marker.langues.includes(globalISOlang)) {
                markerISOlang = globalISOlang;
            } else if(marker.langues.includes('en')) {
                markerISOlang = 'en';
            } else {
                markerISOlang = marker.langues[0];
            }
            markerLang.update(state => {
                state[index] = {
                    langues: marker.langues,
                    markerISOlang
                }
                return state;
            })
        }
    }

    $: types = [
        { 
            title: $_("types.first.title"),
            description: $_("types.first.description"),
            color: "#9d83a6"
        },
        { 
            title: $_("types.second.title"),
            description: $_("types.second.description"),
            color: "#f8ac00"
        },
        { 
            title: $_("types.third.title"),
            description: $_("types.third.description"),
            color: "#008e89"
        }
    ];

    $: sidebarMarkersWidth = width >= 650 ? width/2 : width;

    onMount(() => {
        sidebar = document.querySelector('.sidebar_scroller');
        if($currentMarkerIndex === null) {
            ps = new PerfectScrollbar(sidebar);
        }
    })

    afterUpdate(() => {
    	if($currentMarkerIndex !== null) {
            ps && ps.destroy();
            ps = null;
        } else if($currentMarkerIndex === null) {
            ps = new PerfectScrollbar(sidebar);
        }
        if(showDraw === false) {
            setTimeout(() => {
                var sidebar = document.querySelector('.sidebar');
                sidebar.className = 'sidebar';
            }, 150)
        }
    })

    $: currentTypeTitle = $currentMarkerIndex === null ? '' : types[markers[$currentMarkerIndex].type].title;
    $: currentTypeTitleColor = $currentMarkerIndex === null ? '' : types[markers[$currentMarkerIndex].type].color;
    $: sidebarDrawClass = window.innerWidth <= Consts.mobileLayoutMinWidth ? showDraw ? 'slideindraw' : 'slideoutdraw' : '';
    
    function toggleModal(event) {
        showModal = !showModal;
    }
</script>