<svelte:window on:click={handelWindowClicked} />

<span class="share-btn" on:click="{ toggleShareButtons }">
    <svg><use xlink:href="#icon-share"></use></svg>
</span>
{#if showShareOptions }
<span transition:fly="{{ y: -50, duration: 300 }}" class="share-btn-facebook" on:click="{ (event) => fbShare(event, 520, 350) }">
    <svg><use xlink:href="#icon-facebook"></use></svg>
</span>
<span transition:fly="{{ y: -100, duration: 300 }}" class="share-btn-twitter" on:click="{ (event) => twitterShare(event, $_('twitter_share'), 550, 420, 'oceancampus') }">
    <svg><use xlink:href="#icon-twitter"></use></svg>
</span>
<span transition:fly="{{ y: -150, duration: 300 }}" class="share-btn-iframe" on:click="{ toggleIframeCode }">
    <svg><use xlink:href="#icon-code"></use></svg>
    {#if showIframeCode }
    <span transition:fly="{{ x: -50, duration: 300 }}" class="share-btn-iframe_hover" on:click="{ iframeHoverClick }">
        <textarea on:click="{ textareaClick }"><iframe width="700" height="500" frameborder="0" src="{ iframeUrl }" ></iframe></textarea>
    </span>
    {/if}
</span>
{/if}


<script>
    import { fly } from 'svelte/transition';
    import { _ } from 'svelte-i18n';
    let showIframeCode = false;
	let iframeUrl = window.location.href;

    let showShareOptions = false

    function fbShare(event, winWidth, winHeight) {
        event.stopPropagation();
        var url = window.location.href;
        var winTop = (screen.height / 2) - (winHeight / 2);
        var winLeft = (screen.width / 2) - (winWidth / 2);
        window.open('http://www.facebook.com/sharer.php?u=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
    }

    function twitterShare(event, text, winWidth, winHeight, hashtags) {
        event.stopPropagation();
        var url = window.location.href;
        if(!hashtags) { var hashtags = '' };
        var winTop = (screen.height / 2) - (winHeight / 2);
        var winLeft = (screen.width / 2) - (winWidth / 2);
        window.open('https://twitter.com/intent/tweet?text=' + text + '&url=' + url + '&hashtags=' + hashtags, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
    }

    function toggleShareButtons(event) {
        event.stopPropagation();
        showShareOptions = !showShareOptions; 
        showIframeCode = !showShareOptions;
    }

    function iframeHoverClick(event) {
        event.stopPropagation();
    }

    function toggleIframeCode(event) {
        event.stopPropagation();
        showIframeCode = !showIframeCode
    }

    function textareaClick(event) {
        event.target.select();
    }

    function handelWindowClicked(event) {
        showShareOptions = false;
    }

</script>