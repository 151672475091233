<svelte:window on:keydown={keydown} />

<div class="modal-background" on:click="{ () => dispatch('toggleModal') }"></div>
<div class="modal">
    <div class="modal_header">
        <img class="modal_header_logo-srfe" src="/media-carte-interactive/img/logo-srfe-hor-blanc.png" width="270" alt="Surfrider Foundation Europe logo">
        <span class="modal_header_close" on:click="{ () => dispatch('toggleModal') }">
            <svg><use xlink:href="#icon-close"></use></svg>
        </span>
    </div>
    <div class="modal_body_wrapper">
        <div class="modal_body">
            <h1 class="modal_body_title">{ $_("info_modal.title") }</h1>
            <p class="modal_body_description">{@html $_("info_modal.text") }</p>
            <h2 class="modal_body_subtitle">{ $_("info_modal.subtitle") }</h2>
            <p class="modal_body_partners">
                <span style="float: right; padding-left: 1em;">
                    <img style="vertical-align: top;" width="100" height="auto" src="/media-carte-interactive/img/logo-eu.png" alt="Logo European Union">
                    <img style="margin-left: 5px; margin-top: 2px; vertical-align: top" src="/media-carte-interactive/img/logo-life.png" width="89" height="auto" alt="Logo Life">
                </span>
                {@html $_("info_modal.partner_text") }
            </p>
            <p style="text-align: center; margin-top: 30px;">
                <img style="vertical-align: middle; margin-right: 20px;" src="/media-carte-interactive/img/logo-gite-de-france.png" width="72" height="72" alt="Logo Gite de France">
                <img style="vertical-align: middle; margin-right: 20px;" src="/media-carte-interactive/img/logo-maif.png" width="72" height="72" alt="Logo MAIF">
                <img style="vertical-align: middle; margin-right: 20px;" src="/media-carte-interactive/img/logo-green-resort.jpg" width="92" height="92" alt="Logo Green Resort">
            </p>
        </div>
    </div>
</div>

<script>
    import PerfectScrollbar from 'perfect-scrollbar';
    import { onMount, createEventDispatcher } from 'svelte';
    import { _ } from 'svelte-i18n';


    const dispatch = createEventDispatcher()

    function keydown(event) {
        if(event.key === 'Escape') {
            dispatch('toggleModal');
        }
    }

    onMount(() => {
        let modalBody = document.querySelector('.modal_body_wrapper');
        let modal = document.querySelector('.modal');
        let modalHeader = document.querySelector('.modal_header');
        modalBody.style.height = modal.clientHeight - modalHeader.clientHeight + 'px';
        const ps = new PerfectScrollbar(modalBody);
    })

</script>
