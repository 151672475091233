<div class="marker-info">
    <h1 class="marker-info_title" style="color:{ typeColor }">{ title }</h1>
    <p class="marker-info_intro">{@html intro }</p>
    {#if marker.type === 1 || marker.type === 2 }
        <YoutubeVideo url='{ marker.video }' { index } { youTubeIframeAPIReady } />
    {:else }
        <BeforeAfter photos='{ marker.photos }' { index } on:beforeAfterClicked on:beforeAfterImageLoaded />
    {/if}
    <p class="marker-info_description">{@html description }</p>
    {#if markerHasLink }
    <p>
        <a class="marker-info_btn" style="background-color:{ typeColor }" target="_blank" href="{ linkUrl }">
            { linkTitle }
            <svg><use xlink:href="#icon-external-link"></use></svg> 
        </a>
    </p>
    {/if }
</div>

<script>
    import { markerLang } from '../stores';
    import YoutubeVideo from './YoutubeVideo.html';
    import BeforeAfter from './BeforeAfter.html';

    export let typeColor;
    export let marker;
    export let index;
    export let youTubeIframeAPIReady;
    
    $: lang = $markerLang[index].markerISOlang;
    $: title = marker[lang].title;
    $: intro = marker[lang].intro;
    $: description = marker[lang].description;
    $: markerHasLink = marker[lang].link;
    $: linkTitle = markerHasLink ? marker[lang].link.title : "";
    $: linkUrl = markerHasLink ? marker[lang].link.url : "";

</script>

