<svelte:window on:click={handelWindowClicked} />

<div class="marker-keys" { style } on:click="{ handleToggle }">
	{#each types as type, index }
    	<MarkerKey type='{ type }' index='{ index }' />
    {/each}
    <span class="arrow" style="transform: rotate({deg})"></span>
</div>

<script>
    import { fly, fade } from 'svelte/transition';
    import MarkerKey from './MarkerKey.html';
    export let types;

    let showInfo = false;

    $: deg = (showInfo ? "-90" : "90") + "deg";

    $: style = showInfo ? "max-height: none; flex-direction: column" : "";

    function handleToggle(event) {
    	event.stopPropagation();
    	showInfo = !showInfo;
    }
    function handelWindowClicked(event) {
        showInfo = false;
    }
</script>

<style>
    .arrow {
        display: block;
        position: absolute;
        top: 9px;
        right: 12px;
        height: 13px;
        width: 7px;
        line-height: 13px;
        font-size: 0.8em;
        font-family: swiper-icons;
        transform-origin: center center;
        transition: all 200ms;
        pointer-events: none;
    }
    .arrow:after {
        content: "next";
        font-variant: initial;
        line-height: 1;
    }
</style>