<script>
    import { locale } from 'svelte-i18n';
    import Const from "../Consts";

    let isOpen = false;

    $: currentLang = $locale.substring(0, 2);
    $: deg = (isOpen ? "-90" : "90") + "deg";

    function handleLangChange(lang) {
        locale.set(lang)
        isOpen = false;
    }
</script>

<div class="menu">
    <ul>
    {#each Const.locales as lang}
        <li class:active={lang == currentLang} on:click={ () => handleLangChange(lang)}>{lang}</li>
    {/each}
    </ul>
</div>

<style>
    .menu {
        font-size: 0.75em;
        position: relative;
        margin-bottom: 2em;
    }
    ul {
        list-style: none;
        padding: 2px 0;
        margin: 2px 0 0;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    li {
        text-transform: uppercase;
        cursor: pointer;
        color: rgb(97, 97, 97);
        margin: 0 5px;
        border-radius: 25px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    li.active, li.active:hover {
        background-color: #2484C4;
        color:#fff;
    }
    li:hover {
        background-color: rgb(224, 224, 224);
    }
</style>

